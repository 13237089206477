import {useLoaderData} from '@remix-run/react';
import {AnalyticsPageType, SeoHandleFunction} from '@shopify/hydrogen';
import {json, type LoaderArgs} from '@shopify/remix-oxygen';
import groq from 'groq';
import {Sections} from '~/components/layout';
import {sanityClient} from '~/lib/sanity';
import {getShopifyProducts} from '~/lib/sanityShopify';
import {SECTIONS} from '~/queries/sanity/sections';
import {SEO} from '~/queries/sanity/seo';
import {SanityPage} from '~/types';
import {routeHeaders, CACHE_SHORT} from '~/data/cache';
import {seoPayload} from '~/lib/seo.server';

interface HomeSeoData {
  shop: {
    name: string;
    description: string;
  };
}

export const headers = routeHeaders;

export async function loader({request, context}: LoaderArgs) {
  const page = await sanityClient.fetch<SanityPage>(HOMEPAGE_SANITY_QUERY);

  if (!page) return json({});

  const {sections, seo: sanitySeo} = page;

  const shopifyProducts = await getShopifyProducts(
    sections,
    context.storefront,
  );

  const seo = seoPayload.home({sanitySeo});

  return json(
    {
      seo,
      analytics: {
        pageType: AnalyticsPageType.home,
      },
      sections,
      shopifyProducts,
    },
    {
      headers: {
        'Cache-Control': CACHE_SHORT,
      },
    },
  );
}

export default function Homepage() {
  const {sections, shopifyProducts} = useLoaderData<typeof loader>();

  return (
    <>
      {sections && (
        <Sections sections={sections} shopifyProducts={shopifyProducts} />
      )}
    </>
  );
}

const HOMEPAGE_SANITY_QUERY = groq`
  *[_type == 'home'][0]{
    sections[] {
      ${SECTIONS}
    },
    ${SEO}
  }
`;
